import React, { useMemo } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
//import Helmet from "../component/common/Helmet";
//<Helmet pageTitle="" />
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import PortfolioList from "../elements/portfolio/PortfolioList";
import { isMobile } from "react-device-detect";
import particlesConfig from "./../conf/particlesjs-config.json";

import { Particles } from "@blackbox-vision/react-particles";

const getNumberOfYears = () => {
  const startDate = new Date("2017-01-01");
  const currentDate = new Date();
  const years = currentDate.getFullYear() - startDate.getFullYear();
  return years;
};

const PortfolioLanding = (props) => {
  // Utilizar useMemo para memoizar el resultado de la función
  const numberOfYears = useMemo(() => getNumberOfYears(), []);

  return (
    <div className="active-dark">
      <HeaderThree
        logo="symbol-dark"
        color="color-black"
        isMainPage={true}
        history={props.history}
      />

      <div
        className="slider-activation slider-creative-agency with-particles"
        id="home"
      >
        <div className="frame-layout__particles">
          {process.env.NODE_ENV === "production" && (
            <Particles params={particlesConfig} />
          )}
        </div>
        <div className="bg_image bg_image--27">
          <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className={`inner text-center`}>
                    <h1 style={{ textAlign: "center" }}>Edwin Acosta</h1>
                    <h2 style={{ color: "#F9004D" }}>
                      <TextLoop>
                        <span style={{ fontSize: isMobile ? 25 : 50 }}>
                          {" "}
                          Full Stack Developer.
                        </span>
                        <span style={{ fontSize: isMobile ? 25 : 50 }}>
                          {" "}
                          Backend developer.
                        </span>
                        <span style={{ fontSize: isMobile ? 25 : 50 }}>
                          {" "}
                          Frontend developer.
                        </span>
                        <span style={{ fontSize: isMobile ? 25 : 50 }}>
                          {" "}
                          Entrepreneur.
                        </span>
                        <span style={{ fontSize: isMobile ? 25 : 50 }}>
                          {" "}
                          Engineer.
                        </span>
                      </TextLoop>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Slider Area   */}

      {/* End Slider Area   */}

      {/* Start About Area */}
      <div id="about" className="fix">
        <div className="about-area ptb--120  bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                {!isMobile && (
                  <div className="col-lg-5">
                    <img
                      style={{ borderRadius: 40 }}
                      srcSet="
                              /assets/images/about/Edwin_Acosta_c_scale,w_1005.jpg 1005w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_1249.jpg 1249w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_1400.jpg 1400w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_677.jpg 677w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_925.jpg 925w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_1094.jpg 1094w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_1320.jpg 1320w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_200.jpg 200w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_688.jpg 688w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_1172.jpg 1172w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_1399.jpg 1399w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_375.jpg 375w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_837.jpg 837w"
                      src="/assets/images/about/Edwin_Acosta_c_scale,w_432.jpg"
                      alt="Edwin Acosta"
                    />
                  </div>
                )}
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div
                      className="section-title"
                      style={{ textAlign: "center" }}
                    >
                      <h2 className="title">About me</h2>
                      {isMobile && (
                        <div className="col-lg-5">
                          <img
                            style={{ borderRadius: 40 }}
                            srcSet="
                              /assets/images/about/Edwin_Acosta_c_scale,w_1005.jpg 1005w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_1249.jpg 1249w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_1400.jpg 1400w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_677.jpg 677w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_925.jpg 925w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_1094.jpg 1094w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_1320.jpg 1320w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_200.jpg 200w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_688.jpg 688w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_1172.jpg 1172w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_1399.jpg 1399w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_375.jpg 375w,
                              /assets/images/about/Edwin_Acosta_c_scale,w_837.jpg 837w"
                            src="/assets/images/about/Edwin_Acosta_c_scale,w_432.jpg"
                            alt="Edwin Acosta"
                          />
                          <br />
                          <br />
                        </div>
                      )}
                      <p
                        className="description"
                        style={{ textAlign: "justify" }}
                      >
                        I have {numberOfYears} years of experience as a Full
                        Stack Developer, specializing in creating innovative
                        solutions for companies and personal projects. As an
                        entrepreneurial individual, I am deeply passionate about
                        pushing the boundaries of technology and finding
                        practical solutions to real-world problems. I pride
                        myself on being a self-taught learner, always eager to
                        explore new technologies and embrace exciting
                        challenges.
                      </p>
                      <p
                        className="description"
                        style={{ textAlign: "justify" }}
                      >
                        In addition to my technical expertise, I hold a degree
                        in Multimedia Engineering from the Nueva Granada
                        Military University of Colombia. This educational
                        background has endowed me with a profound understanding
                        of the UX/UI design domain, allowing me to foster strong
                        empathy with users and create exceptional user
                        experiences. Throughout my career, I have successfully
                        led development teams in various organizations,
                        employing agile methodologies to drive efficient and
                        collaborative workflows.
                      </p>
                    </div>
                    <div className="row mt--30">
                      <TabTwo tabStyle="tab-style--1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End About Area */}

      {/* Start Portfolio Area */}
      <div id="portfolio" className="fix">
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">My latest projects</h2>
                    <p>
                      I have actively contributed to and provided leadership in
                      a diverse range of projects, encompassing the following
                      highlights:
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <PortfolioList
                  styevariation="text-center mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                  item="3"
                />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="view-more-btn mt--60 mt_sm--30 text-center">
                    <button
                      className="rn-button-style--2 btn-solid"
                      type="button"
                      name="full-portfolio"
                      id="full-portfolio"
                      onClick={() => props.history.push("/full-portfolio")}
                    >
                      {"See more"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

      {/* Start COntact Area */}
      <div id="contact" className="fix">
        <div className="rn-contact-area ptb--120 bg_color--1">
          <ContactThree
            contactImages="/assets/images/about/about-9.jpg"
            contactTitle="Hire me"
          />
        </div>
      </div>
      {/* End COntact Area */}

      <FooterTwo />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default PortfolioLanding;
