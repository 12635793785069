import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class TabsTwo extends Component {
  render() {
    let tab1 = "Main skills",
      tab2 = "Awards",
      tab3 = "Expertise",
      tab4 = "Education";
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                    <Tab>{tab4}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <b>Project Leader</b>
                          <br></br>A collaborative professional with a strong
                          passion for leading teams effectively and achieving
                          collective goals
                        </li>
                        <li>
                          <b>Dapp Developer</b>
                          <br></br>
                          Proficient in utilizing React JS and Web3 Library to
                          develop decentralized applications, including
                          expertise in smart contract implementation
                        </li>
                        <li>
                          <b>Backend Developer</b>
                          <br></br>
                          Skilled in JavaScript (Node/NestJS) and Java (Spring
                          Boot) for building robust and efficient backend
                          systems.
                        </li>
                        <li>
                          <b>Frontend developer</b>
                          <br></br>
                          Experienced in creating engaging user interfaces using
                          React JS/Native, along with expertise in CSS and HTML.
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <b>APPPS.CO</b>
                          <br></br>
                          Successfully advanced to the second phase{" "}
                          <b>
                            Convocatoria a equipos emprendedores para la fase
                            Descubrimiento de Negocios Digitales - Iteración XIV
                            2020.
                          </b>
                        </li>
                        <li>
                          <b>SENASOFT</b>
                          <br></br>
                          Earned recognition through merit-based participation
                          in the prestigious national technology event, SENASOFT
                          Santander 2015.
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <b>Revstarconsulting</b>
                          <br></br>
                          Currently, I am employed as a Senior Developer.
                        </li>
                        <li>
                          <b>
                            Imasmas Ingeniería Informatica y de Seguridad SL
                          </b>
                          <br></br>
                          Full Stack Developer with a focus on Dapps and Smart
                          Contracts.
                        </li>
                        <li>
                          <b>Bloomcrowdfunding</b>
                          <br></br>
                          From June 2020 to July 2021, I worked as a Full Stack
                          Developer.
                        </li>
                        <li>
                          <b>Golky</b>
                          <br></br>I held the position of Development Lead from
                          May 2019 to February 2020.
                        </li>
                        <li>
                          <b>Coronaviruscol</b>
                          <br></br>
                          From March 2020 to April 2020, I served as a
                          Development Lead.
                        </li>
                        <li>
                          <b>BitbCorporation</b>
                          <br></br>I worked as a Software Developer from January
                          2016 to November 2019.
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <b>Servicio Nacional De Aprendizaje (SENA)</b>
                          <br></br>
                          Software Development Technologist, January 2015-June
                          2017
                        </li>
                        <li>
                          <b>Universidad Militar Nueva Granada</b>
                          <br></br>
                          Multimedia Engineer degree, August 2017 - December
                          2022
                        </li>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
