import React, { Component } from "react";
import { Helmet } from 'react-helmet'

class PageHelmet extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{"Edwin Acosta •"}{this.props.pageTitle && (" " + this.props.pageTitle)}</title>
                    <meta name="description" content="Edwin Acosta - Full stack developer" />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
