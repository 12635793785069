// React Required
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from "history";
import DarkPortfolioLanding from './dark/PortfolioLanding';
import Portafolio from './dark/Portafolio';
import error404 from "./elements/error404";
import './index.scss';

var hist = createBrowserHistory();

var firebaseAnalytics = window.firebaseAnalytics || [];

const Root = () => {
    return (
        <Router history={hist}>
            <Switch>
                <Route exact path={`${process.env.PUBLIC_URL}/`} component={DarkPortfolioLanding} />
                <Route exact path={`${process.env.PUBLIC_URL}/full-portfolio`} component={Portafolio} />

                <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
                <Route component={error404} />

            </Switch>
        </Router>
    );
};

hist.listen(() => {
    firebaseAnalytics.setCurrentScreen(window.location.pathname + window.location.search);
    firebaseAnalytics.logEvent('screen_view');
});

ReactDOM.render(<Root />, document.getElementById('root'));
//serviceWorker.register();