import React, { Component } from "react";
import { isMobile } from "react-device-detect";

const PortfolioListContent = [
  {
    bgColor: "#fff",
    letterColor: "#000",
    title: "Golky Punks",
    detail:
      "Golky Punks is a collection of randomly generated avatars with their metadata stored on the blockchain. Each avatar offers a unique appearance and can be owned by individuals.",
    image: "logoGolkyPunks.png",
    link: "https://punks.golky.co",
  },
  {
    bgColor: "#fff",
    letterColor: "#000",
    title: "Golky",
    detail:
      "Golky is an upcoming startup that aims to provide amateur soccer players with a professional experience. The platform offers various features and services created to enhance the soccer journey for players of all levels.",
    image: "logoGolky.png",
    link: "https://golky.co/",
  },
  {
    bgColor: "#FFBC00",
    letterColor: "#000",
    title: "CopyWrite",
    detail:
      "CopyWrite is a Colombian startup focused on managing university tutorials. Our platform streamlines the process of connecting students with qualified tutors, ensuring a seamless learning experience.",
    imageUrl: "https://www.copywritecol.com/icon.png",
    link: "https://www.copywritecol.com/",
  },
  {
    bgColor: "#fff",
    letterColor: "#000",
    title: "Bloomcrowdfunding",
    detail:
      "Bloomcrowdfunding is a Colombian fintech platform that brings together entrepreneurs and investors to fund projects. Our platform provides a secure and transparent environment for crowdfunding campaigns, enabling the realization of innovative ideas.",
    image: "logoBloom.webp",
    link: "https://www.bloomcrowdfunding.co/",
  },
  {
    bgColor: "#0f175e",
    letterColor: "#fff",
    title: "CoronavirusCol",
    detail:
      "CoronavirusCol is a platform that supports the Colombian healthcare system by offering free online medical consultations. Our goal is to provide accessible and timely healthcare services to individuals in need.",
    image: "logoCoronavirus.png",
    link: "https://coronaviruscol.com/",
  },
  {
    bgColor: "#fff",
    letterColor: "#000",
    title: "Computer Graphics",
    detail:
      "Computer Graphics is an academic course offered at the New Granada Military University. The course explores various techniques and algorithms used in creating visually stunning computer-generated imagery.",
    image: "cg.png",
    link: "https://acostaedwin.github.io/Computer-graphics---Projects-/",
  },
  {
    bgColor: "#CE1A19",
    letterColor: "#fff",
    title: "University Channel",
    detail:
      "The University Channel is a platform provided by the New Granada Military University, showcasing educational content, research findings, and insightful discussions from students and faculty members.",
    image: "yt.png",
    link: "https://www.youtube.com/channel/UCazK0LZyQ3BKnUDOBIczj8g",
  },
  {
    bgColor: "#FF9315",
    letterColor: "#fff",
    title: "Friends App Social",
    detail:
      "Discover and share videos, photos, audio, and music with your friends through the Friends App Social. Stay connected and explore shared content in a fun and interactive way.",
    image: "logoFriends.png",
    link: "https://www.friendscolombia.com/",
  },
];

class PortfolioList extends Component {
  render() {
    const { column, styevariation } = this.props;
    const list = PortfolioListContent.slice(0, this.props.item);
    return (
      <React.Fragment>
        {list.map((value, index) => (
          <div className={`${column}`} key={index}>
            <div className={`portfolio ${styevariation}`}>
              <div
                className="thumbnail-inner"
                style={{ backgroundColor: value.bgColor }}
              ></div>
              <div className="content">
                <img
                  src={
                    value.image
                      ? `/assets/images/portfolio/${value.image}`
                      : value.imageUrl
                  }
                  alt="Logo images"
                  style={{
                    width: isMobile ? 100 : 180,
                    zIndex: 10,
                    position: "relative",
                  }}
                />
                <div className="inner">
                  <h3 style={{ color: value.letterColor }}>{value.title}</h3>
                  <span style={{ color: value.letterColor }}>
                    {value.detail}
                  </span>
                  <div className="portfolio-button">
                    {value.letterColor !== "#fff" ? (
                      <a
                        className="rn-btn"
                        target={"_blank"}
                        href={value.link}
                        style={{
                          color: value.letterColor,
                          borderColor: "#000",
                        }}
                      >
                        {"Open"}
                      </a>
                    ) : (
                      <a
                        className="rn-btn"
                        target={"_blank"}
                        href={value.link}
                        style={{
                          color: value.letterColor,
                        }}
                      >
                        {"Open"}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default PortfolioList;
