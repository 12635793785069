import React, { Component } from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";
import Scrollspy from "react-scrollspy";

const SocialShare = [
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/acostaedwin/",
  },
  //{ Social: <FaInstagram />, link: 'https://www.instagram.com/_acosta_edwin/' },
];
class HeaderThree extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  stickyHeader() {}

  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { color = "default-color" } = this.props;
    let logoUrl = (
      <img
        src="/assets/images/logo.png"
        alt="Edwin Acosta"
        style={{ width: 50 }}
      />
    );

    return (
      <header className={`header-area header-style-two header--fixed ${color}`}>
        <div className="header-wrapper">
          <div className="header-left d-flex align-items-center">
            <div className="logo">
              <a href={"/"}>{logoUrl}</a>
            </div>
            <nav className="mainmenunav d-lg-block ml--50">
              {this.props.isMainPage ? (
                <Scrollspy
                  className="mainmenu"
                  items={["home", "about", "portfolio", "contact"]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li>
                    <a href="#home" onClick={this.CLoseMenuTrigger}>
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="#about" onClick={this.CLoseMenuTrigger}>
                      About me
                    </a>
                  </li>
                  <li>
                    <a href="#portfolio" onClick={this.CLoseMenuTrigger}>
                      Portfolio
                    </a>
                  </li>
                  <li>
                    <a href="#contact" onClick={this.CLoseMenuTrigger}>
                      Contact / CV
                    </a>
                  </li>
                </Scrollspy>
              ) : (
                <Scrollspy
                  className="mainmenu"
                  items={["home", "about", "portfolio", "contact"]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li>
                    <a
                      href="#home"
                      onClick={() => this.props.history.push("/")}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="#about"
                      onClick={() => this.props.history.push("/#about")}
                    >
                      About me
                    </a>
                  </li>
                  <li>
                    <a
                      href="#portfolio"
                      onClick={() => this.props.history.push("/#portfolio")}
                    >
                      Portfolio
                    </a>
                  </li>
                  <li>
                    <a
                      href="#contact"
                      onClick={() => this.props.history.push("/#contact")}
                    >
                      Contact / CV
                    </a>
                  </li>
                </Scrollspy>
              )}
            </nav>
          </div>
          <div className="header-right">
            <div className="social-share-inner">
              <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`} target={"_blank"}>
                      {val.Social}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderThree;
