import React from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import PortfolioList from "../elements/portfolio/PortfolioList";
import { scrollToTop } from "../functions/scrollToTop";

const PortfolioLanding = (props) => {
  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="active-dark">
      <Helmet pageTitle="Portfolio" />
      <HeaderThree
        logo="symbol-dark"
        color="color-black"
        isMainPage={false}
        history={props.history}
      />

      {/* Start Portfolio Area */}
      <div id="portfolio" className="fix" style={{ paddingTop: 100 }}>
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h4 className="title">Portfolio</h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <PortfolioList
                  styevariation="text-center mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

      <FooterTwo />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default PortfolioLanding;
