import React, { Component } from "react";

import { ReactComponent as IconSio } from "./../../open.svg";

class ContactThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rnName: "",
      rnEmail: "",
      rnSubject: "",
      rnMessage: "",
    };
  }
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-12" style={{ textAlign: "center" }}>
              <h2>Let's work together</h2>
              <p style={{ color: "#717173" }}>
                I am available for freelance work. connect with me on mobile:{" "}
                <a href="tel:+573118847887">+57 311 884 7887</a> or mail:{" "}
                <a href="mailto:acostaedwin1@gmail.com">
                  acostaedwin1@gmail.com
                </a>
                .
              </p>
              <hr></hr>

              <p style={{ color: "#717173" }}>
                <a
                  href={
                    "https://acostaedwin.s3.amazonaws.com/Edwin_Acosta-Full_Stack_Developer-en.pdf"
                  }
                  target={"_blank"}
                  style={{ color: "#F9004D" }}
                >
                  Curriculum vitae
                  <IconSio style={{ width: 60, padding: 10 }} />
                </a>
              </p>
              <p style={{ color: "#717173" }}>
                <a
                  href={
                    "https://acostaedwin.s3.amazonaws.com/Edwin_Acosta-Full_Stack_Developer-es.pdf"
                  }
                  target={"_blank"}
                  style={{ color: "#F9004D" }}
                >
                  <span>Hoja de vida (Español)</span>
                  <IconSio style={{ width: 60, padding: 10 }} />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactThree;
